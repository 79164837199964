.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 55vh;
  max-width: 30rem;
  background-color: white;
  padding: 0 1rem 1rem 1rem;
  border-radius: 8px;
  position: absolute;
  top: 20%;
  left: 38%;
}
.disabledButton {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (min-height: 601px) and (max-height: 800px) {
  .dropdown-container {
    max-height: 20vh;
  }
}

@media (min-height: 801px) {
  .dropdown-container {
    max-height: 32vh;
  }
}
