.user_name {
  color: #3c3e49;
  font-size: 14px;
  letter-spacing: 0.02857em;
  line-height: 1.25rem;
  font-family: "Rubik", sans-serif;
}

.BuilderLogo {
  height: 1.8em;
  width: 8em;
}

.BuilderLogoB {
  width: 2.2em;
  height: 2.5em;
}

.LogoGrid {
  padding: 1.5em 0em 1.5em 1.1em !important;
  height: 5em;
}

.userprofile {
  position: absolute;
  bottom: 1em;
  left: 1em;
  width: auto !important;
}

.user_logo {
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  background-color: #8f5ff7;
  color: white;
}

.closeArrowButton {
  border: 1px solid rgb(101, 31, 255, 0.3) !important;
  margin-top: 1.5em !important;
  z-index: 99 !important;
  background-color: white !important;
  height: 0.8em;
  width: 0.8em;
}

.drawerDiv {
  z-index: 0;
  box-shadow: 2px 2px 4px rgb(101, 31, 255, 0.2);
  height: 100vh;
}

.react-confirm-alert-body {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));

  /* Body/SM/Regular */
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00875rem;
}

.react-confirm-alert-body > h1 {
  color: var(--Base-Blue-grey-10, #3c3e49);

  /* Body/XL/Medium */
  font-size: 1.25rem;
  font-style: normal;
  line-height: 2rem; /* 160% */
}

.react-confirm-alert-body button[label="Go back"] {
  display: flex;
  height: 2.25rem;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: var(--Base-Grey-3, #eee);
  color: var(--True-Grey-9, var(--Base-Grey-9, #262626));
}

.react-confirm-alert-body button[label="Log out"] {
  display: flex;
  height: 2.25rem;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: var(--Interactive-Bg-Err, #ff8a80);
  color: var(--True-Grey-9, var(--Base-Grey-9, #262626));
}

.userLogout:hover {
  cursor: pointer;
}

.closeOpenArrow {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 55px;
  z-index: 999;
  display: flex;
}
.closeOpenArrowActive {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 11.8em;
  z-index: 999;
  display: flex;
}
