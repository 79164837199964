.comment_mail_button {
  background-color: grey !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: black !important;
}

.comment_mail_field {
  border-radius: 4px ;
  background-color: rgba(255, 255, 255, 1);
  
}
