.sla_breached {
    display: "flex";
    width: "7em";
    height: "4em";
    padding: "0.9375em 0em";
    flex-direction: "column";
    justify-content: "center";
    align-items: "flex-start";
    gap: "1em";
    flex-shrink: "0";
}