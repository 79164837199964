.comingSoon{
    position: fixed;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
}
.spanStyle{
    margin-top: 0.5em;
    position: fixed;
    left: 55%;
    top: 110%;
    transform: translate(-50%, -50%);
    font-size: 1em;
    width: 100%;
    color: rgb(0,0,0,0.5);
}