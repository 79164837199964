.dashboard_date_filter_container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  padding: 5px;
}

.filter_by_date {
  color: #3c3e49;
  font: 400 14px / 143% Rubik, sans-serif;


  line-height: 1.25rem; 
  letter-spacing: 0.00875rem;
}

.date_filter_i_icon {
  display: inline-flex;
  align-items: center;
}
