.dashboardComponent {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 2.3rem 2rem 2rem 1rem;
  overflow: hidden;
  background-color: var(--Base-Blue-grey-0, #fafbfc);
}

.header {
  width: 100%;
  position: fixed;
  z-index: 10;
  background-color: var(--Base-Blue-grey-0, #fafbfc);
  font-family: Rubik, sans-serif;
}

.content {
  margin-top: 7.5rem;
  flex: 1;
  margin-right: -2.8rem;
  padding-right: 2rem;
  overflow-y: scroll;
}

.ticket_metrics_dashboard_text {
  color: var(--Base-Blue-grey-10, #3c3e49);
  font-size: 1.25rem;
  font-style: normal;
  line-height: 2rem;
}

.dashboard_filters {
  flex-grow: 1;
  display: flex;
}

.dashbaord_cards_container {
  border-radius: 0.5rem;
  border: 1px solid var(--Base-Purple-1, #f3ebff);
  background: var(--0, #fff);
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1.5rem;
}

.dashboard_cards_heading {
  color: var(--Base-Blue-grey-10, #3c3e49);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.view_disclaimer {
  cursor: pointer !important;
  color: #676b7e;
  padding-left: 1.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500 !important;
}

.dashboard_closed_first_response_sectiion {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.first_response_sla {
  background-color: white;
  width: 20%;
  padding: 1rem;
  border: 1px solid #f3ebff;
  border-radius: 0.5rem;
}
.closed_tickets_cards_section {
  width: 78%;
  background-color: white;

  padding: 1rem;

  border: 1px solid #f3ebff;
  border-radius: 0.5rem;
}

.dashboard_filters_row {
  display: flex;
}

.dashboard_filters_row > div {
  margin-right: 4vw;
}

.dashboard_open_tickets {
  display: flex;
  align-items: center;
}

.sla_badge {
  display: flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.625rem;
}

.sla_icon {
  display: flex;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0rem 0.125rem;
  justify-content: center;
  align-items: center;
}

.sla_icon > img {
  width: 0.5rem;
  height: 0.03125rem;
  flex-shrink: 0;
}

.dashboard_cards_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  border: #f3ebff;
  background: #fff;
}

.card {
  display: flex;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
  border-radius: 0.25rem;
  border-right: 1px solid rgba(179, 136, 255, 0.33);
  background: var(--0, #fff);
  font-family: "Rubik", sans-serif;
}

.no_right_border_card {
  border-right: 0;
}

.card:hover {
  cursor: pointer;
  background: rgba(251, 248, 255, 0.6);
}

.card_title_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.card_title_row img {
  width: 1rem;
  height: 1rem;
}

.card_title_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.card_sla_title_text {
  color: var(--Base-Blue-grey-10, #3c3e49);
  text-align: center;

  font-size: 1rem;
  font-style: normal;

  line-height: 1.5rem;
}

.card_progress_row {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
}

.sla_ticket_percentage {
  color: var(--Base-Blue-grey-10, #3c3e49);
  text-align: center;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.015rem;
}

.card_progress_bar {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.625rem;
}

.card_progress_view {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.625rem;
}

.count_out_of_total {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.count_percentage_value,
.count_value_total {
  color: var(--Base-Blue-grey-9, #6d7286);
  font-size: 0.875rem;
  font-style: normal;

  line-height: 1.25rem;
  letter-spacing: 0.00875rem;
}

.display_selected_filters_dashboard {
  padding-left: 1.8rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.hide-all-cards {
  display: none;
}

.show-all-cards {
  display: block;
}

.dashboard_closed_cards_row {
  display: flex;
}

.datePickerBtnContainer {
  display: flex;
  justify-content: space-between;
  width: 18em;
}

.charDisplay {
  display: flex;
  justify-content: space-between;
}

.barChartDisplay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
