.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40% !important;
}

.ticket_tag {
  padding: 0rem 0.6rem;
  background-color: rgba(247, 245, 255, 1);
  border-radius: 0.8rem;
  color: rgba(79, 49, 228, 1);
  border: 1px solid rgba(188, 177, 245, 1);
  /* min-width: 3rem; */
  width: fit-content;
  text-align: center;
  vertical-align: middle;
  font-size: 0.8rem;
}

.MuiIcon-root {
  width: auto !important;
  height: 1.2em !important;
}
