.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-value);
}

.ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

.ql-font-Rubik {
  font-family: "Rubik",sans-serif;
}
.ql-font-Arial {
  font-family: Arial,sans-serif;
}
.ql-font-Courier {
  font-family: Courier,sans-serif;
}
.ql-font-Tahoma {
  font-family: Tahoma,sans-serif;
}
.ql-font-Verdana {
  font-family: Verdana,sans-serif;
}
.ql-font-Times {
  font-family: Times,sans-serif;
}
.ql-size-14px {
  font-size: 14px !important;
}
.ql-size-16px {
  font-size: 16px !important;
}
.ql-size-18px {
  font-size: 18px !important;
}
.ql-size-24px {
  font-size: 24px !important;
}
.ql-size-32px {
  font-size: 32px !important;
}
.ql-size-48px {
  font-size: 48px !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0rem;
}

.ql-snow .ql-picker.ql-font {
  width: 5rem !important;
}

.ql-snow .ql-picker.ql-size {
  width: 4rem !important;
}

.ql-toolbar.ql-snow {
  background-color: #ecedf0;
}

.quill-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .ql-toolbar .ql-formats {
    display: none;
  }
  .ql-toolbar .dropdown {
    display: block;
    position: relative;
  }
  .ql-toolbar .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .ql-toolbar .dropdown:hover .dropdown-content {
    display: block;
  }
  .ql-toolbar .dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
}

.quill-editor.hidden .ql-toolbar.ql-snow {
  display: none !important;
  margin-top: -1em;
}
.quill-editor .ql-toolbar.ql-snow {
  display: block !important;
}

.description_box .ql-container.ql-snow.ql-disabled {
  padding: 0;
}

.ql-editor {
  overflow: auto;
  scrollbar-width: none;
  padding: 0;
}
