.actionBar {
  line-height: 6rem;
  text-align: end;
  font-size: 14px;
}

.addTeamButton {
  background-color: #f3ebff !important;
  color: #262626 !important;
  font-size: 0.7em !important;
  border-radius: 8px !important;
  width: 6.875rem;
  height: 2.25rem;
}
.addTeamButtonDisable {
  background-color: #f3ebff !important;
  color: #262626 !important;
  font-size: 0.7em !important;
  border-radius: 8px !important;
  width: 110px;
  height: 36px;
  opacity: 0.5;
  pointer-events: fill !important;
  cursor: not-allowed !important;
}

.mainTable {
  max-width: 98% !important;
}

.checkboxText {
  padding-left: 1em;
  line-height: 3em;
}

.tableCells {
  border: 0.5px solid var(--Base-Blue-grey-3, #dfe0e6);
  font-size: 12px !important;
  font-family: Rubik, sans-serif !important;
  color: #3c3e49;
  font-style: normal;
}
.tableCellsTeamName {
  border: 0.5px solid var(--Base-Blue-grey-3, #dfe0e6);
  font-size: 12px !important;
  color: #3c3e49;
  font-style: normal;
  line-height: 16px;
}
.actionTableCells {
  border: 0.5px solid var(--Base-Blue-grey-3, #dfe0e6);
  font-size: 12px !important;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
}
.tableNameColumn {
  font-size: 12px !important;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
}
.Mui-selected {
  background-color: #f3ebff !important;
}
.tableHead {
  z-index: 1;
  color: #3c3e49;
  background-color: #dfe0e6 !important;
}

.editTeamButton {
  background-color: #f3ebff !important;
  color: #262626 !important;
  font-size: 0.7em !important;
  border-radius: 8px !important;
  margin-right: 0.5em !important;
}
.editTeamButton:hover {
  cursor: pointer;
}

.deleteTeamButton {
  background-color: #f3ebff !important;
  color: #262626 !important;
  font-size: 0.7em !important;
  border-radius: 8px !important;
}
.deleteTeamButton:hover {
  cursor: pointer;
}

.activeMembers {
  height: 1.6em;
  width: 9rem !important;
  background: #ebf5f1;
  border-radius: 32px;
  border: 1px solid #b3dac7;
  color: #008243;
  font-style: normal;
  display: flex;
  padding-left: 8px;
  justify-content: left;
  align-items: center;
}

.actionButtonMenu {
  border-radius: 8px !important;
  border: 1px solid rgba(179, 136, 255, 0.3) !important;
  background: var(--Interactive-Bg-Sec, #fff);
}

/* .username {
  display:flex;
  flex-direction: column;
  margin-left: -0.4em;
  padding-right: 0.2em;
  padding-left:0.4em;
} */

.username {
  display: flex;
}

.userText {
  /* border:1px solid red; */
  display: flex;
  align-items: center;
}

.userAvatar {
  padding-left: 0.4rem;
  padding-right: 0.6rem;
}

th.usernameContainer {
  padding: 0;
}

.usersManager {
  height: 1.5em;
  width: 6em !important;
  background: #ecf2fc;
  border-radius: 32px;
  border: 1px solid #b6cff3;
  color: #0b5cd7;
  font-style: normal;
  display: flex;
  /* padding-left: 8px; */
  justify-content: left;
  align-items: center;
}

.usersUser {
  height: 1.5em;
  width: 4em !important;
  background: #f8f9fa;
  border-radius: 32px;
  border: 1px solid #afb2c0;
  color: #595959;
  font-style: normal;
  display: flex;
  /* padding-left: 8px; */
  justify-content: left;
  align-items: center;
}

.usersActive {
  height: 1.5em;
  max-width: 5rem !important;
  min-width: 5rem !important;
  background: #ebf5f1;
  border-radius: 32px;
  border: 1px solid #b3dac7;
  color: #008243;
  /* font-style: normal; */
  /* display: flex; */
  padding-left: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;
}

.usersInactive {
  height: 1.5em;
  max-width: 5rem !important;
  min-width: 5rem !important;
  background: rgba(239, 237, 237, 0.5);
  border-radius: 32px;
  border: 1px solid #afb2c0;
  color: #595959;
  /* font-style: normal; */
  /* display: flex; */
  padding-left: 8px;
  justify-content: flex-start;
  align-items: center;
}

.tableMainContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tableMainContainer::-webkit-scrollbar {
  display: none;
}

.styledTableRow {
  background-color: rgba(239, 237, 237, 0.5);
}
.expandIconCell {
  padding-left: 2rem !important;
  border-right: 0.5px solid red;
}

.shiftHeadingText {
  font-size: 0.9rem !important;
  padding: 6px 6px 0px 6px;
}
