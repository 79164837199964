.modal-header {
  padding: 1.5rem;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  padding: 1.5rem;
  flex: 1;
  overflow-y: auto;
  scrollbar-color: #d3d4d6 white;
  scrollbar-width: thin;
  max-height: calc(100% - 60px);
}

.modal-content-editor {
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  position: relative;
  min-height: 10rem;
  max-height: 13rem;
  overflow-y: auto;
  scrollbar-color: #d3d4d6 white;
  scrollbar-width: none;
}

.modal-content-editor.not-editing {
  padding: 1rem;
}

.modal-content-editor .ql-container.ql-snow.ql-disabled {
  padding: 0;
}

.escalation-editor {
  margin-bottom: 1rem;
}

.escalation-editor .ql-toolbar.ql-snow {
  width: 100%;
  position: absolute;
  z-index: 10;
  border: none;
  border-bottom: 1px solid #d3d4d6;
}

.escalation-editor .ql-container.ql-snow {
  border: none;
  padding: 2.8rem 1rem 0 1rem;
  min-height: 12rem;
  max-height: 14rem;
  overflow-y: auto;
  scrollbar-color: #d3d4d6 white;
  scrollbar-width: none;
}

.escalation-editor .modal-content-editor.not-editing .ql-container.ql-snow {
  border: none;
  min-height: 10rem;
  max-height: 12rem;
  overflow-y: auto;
  scrollbar-color: #d3d4d6 white;
  scrollbar-width: none;
}

.modal-button {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
