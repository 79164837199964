.ticketSummaryScreen {
  width: 100%;
  overflow: hidden;
}

.div {
  /* background-color: var(--Base-Blue-grey-0, #fafbfc); */
  /* display: flex; */
  height: 100vh;
  padding-right: 32px;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .div {
    flex-wrap: wrap;
    padding-right: 20px;
  }
}
.div-2 {
  justify-content: space-between;
  box-shadow: 0px 4px 16px 3px rgba(0, 0, 0, 0.15);
  background-color: var(--Base-Blue-grey-0, #fafbfc);
  display: flex;
  flex-basis: 0%;
  flex-direction: column;
  padding: 26px 0 26px 16px;
  height: 100vh;
}
.div-3 {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 14px;
}
.div-4 {
  align-items: flex-start;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
}
.img {
  aspect-ratio: 4.88;
  object-fit: contain;
  object-position: center;
  width: 117px;
  align-items: center;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.span {
  align-self: stretch;
  display: flex;
  margin-top: 28px;
  justify-content: flex-start;
  gap: 8px;
}
.img-2 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-5 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.14px;
  font: 400 14px/143% Rubik, sans-serif;
}
.div-6 {
  border-radius: 4px;
  background-color: var(--Base-Purple-1, #f3ebff);
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 4px 0;
}
.span-2 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
.img-3 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-7 {
  color: var(--Base-Purple-6, #6200ea);
  letter-spacing: 0.14px;
  font: 500 14px/143% Rubik, sans-serif;
}
.span-3 {
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  gap: 8px;
}

.notificationsContainer {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  padding: 4px;
}

.img-4 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-8 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.14px;
  font: 400 14px/143% Rubik, sans-serif;
}
.div-9 {
  justify-content: flex-start;
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  gap: 20px;
  padding: 4px;
}

.span-4 {
  /* display: flex;
  justify-content: flex-start;
  gap: 8px; */
  border: 1px solid blue;
}
.img-5 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-10 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.14px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-10 {
    white-space: initial;
  }
}
.span-5 {
  color: #fff;
  letter-spacing: 0.12px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--Base-Purple-6, #6200ea);
  aspect-ratio: 1;
  height: 20px;
  padding: 0 6px;
  font: 500 12px/133% Rubik, sans-serif;

  /* border:1px solid red; */
}
@media (max-width: 991px) {
  .span-5 {
    white-space: initial;
  }
}
.img-6 {
  aspect-ratio: 0.04;
  object-fit: contain;
  object-position: center;
  width: 2px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.span-6 {
  display: flex;
  margin-top: 592px;
  justify-content: space-between;
  gap: 8px;
}
@media (max-width: 991px) {
  .span-6 {
    margin-top: 40px;
  }
}

.user_profile {
  display: flex;
}

.div-11 {
  align-items: center;
  border-radius: 100px;
  background-color: var(--0, #fff);
  display: flex;
  aspect-ratio: 1.5625;
  padding-right: 18px;
  flex-direction: column;
  justify-content: center;
}
.img-7 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-12 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.14px;
  margin: auto 0;
  font: 400 14px/143% Rubik, sans-serif;
}
.div-13 {
  align-self: end;
  display: flex;
  margin-left: 2vh;
  margin-top: 32px;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-13 {
    max-width: 100%;
  }
}
.span-7 {
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  gap: 20px;
}
@media (max-width: 991px) {
  .span-7 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-14 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  /* margin: auto 0; */
  /* font: 500 20px/160% Rubik, sans-serif; */
  /* margin-top: -1.5em; */
  /* font-size: 1.5em; */

  font-size: 1.25rem;
  font-style: normal;

  line-height: 2rem; /* 160% */
}
.div-15 {
  align-self: stretch;
  display: flex;
  gap: 16px;
}
@media (max-width: 991px) {
  .div-15 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.span-8 {
  border-radius: 4px;
  border: 1px solid var(--Blue-Grey-5, #c0c3ce);
  display: flex;
  gap: 8px;
  padding: 8px;
  width: 18rem;
  height: 2.5rem;
}
@media (max-width: 991px) {
  .span-8 {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
}
.img-8 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 1.2rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-16 {
  color: var(--Blue-Grey-8, var(--Base-Blue-grey-8, #83889e));
  letter-spacing: 0.14px;
  width: 14rem;
  font: 400 14px/143% Rubik, sans-serif;
  border: 0;
}
input:focus {
  outline: none;
}
.div-17 {
  justify-content: center;
  border-radius: 8px;
  background-color: var(--Interactive-Bg-P1, #00e676);
  display: flex;
  flex-basis: 0%;
  flex-direction: column;
  padding: 8px 24px;
}
@media (max-width: 991px) {
  .div-17 {
    padding: 0 20px;
  }
}
.span-9 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  gap: 8px;
}
.img-9 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 0.8rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
}
.div-18 {
  color: var(--Interactive-Label-P1-P2-E1, #262626);
  letter-spacing: 0.14px;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-18 {
    white-space: initial;
  }
}
.div-19 {
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
  width: 100%;
  gap: 20px;
  /* border:1px solid green; */
}
@media (max-width: 991px) {
  .div-19 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.span-10 {
  justify-content: space-between;
  border-radius: 4px;
  border: 0.8px solid var(--Base-Blue-grey-7, #9b9fb1);
  display: flex;
  gap: 12px;
  padding: 8px 16px 8px 8px;
  cursor: pointer;
}
.div-20 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.14px;
  font: 400 14px/143% Rubik, sans-serif;
}
.img-10 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 8px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.div-21 {
  justify-content: center;
  border-radius: 8px;
  background-color: var(--Interactive-Bg-T1, #f5f5f5);
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
}
.span-11 {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.img-11 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-22 {
  color: var(--True-Grey-9, var(--Base-Grey-9, #262626));
  letter-spacing: 0.14px;
  font: 500 14px/143% Rubik, sans-serif;
}
.div-23 {
  border-radius: 4px;
  border: 0.5px solid var(--Base-Blue-grey-5, #c0c3ce);
  background-color: var(--0, #fff);
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-23 {
    max-width: 100%;
  }
}
.div-24 {
  border-radius: 4px;
  background-color: var(--Base-Grey-2, #f5f5f5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 991px) {
  .div-24 {
    max-width: 100%;
  }
}
.span-12 {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 24px 15px;
}
@media (max-width: 991px) {
  .span-12 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-25 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 10px/160% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-25 {
    white-space: initial;
  }
}
.div-26 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.div-27 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.div-28 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.div-29 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.div-30 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.div-31 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.div-32 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.div-33 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 10px/160% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-33 {
    white-space: initial;
  }
}
.span-13 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-13 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-34 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.span-14 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-14 {
    padding-right: 20px;
  }
}
.img-12 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-35 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-35 {
    white-space: initial;
  }
}
.div-36 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Amber-5, #ffab00);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-13 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-37 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-38 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.div-39 {
  align-self: center;
  display: flex;
  gap: 0px;
  margin: auto 0;
}
.span-15 {
  color: var(--Brand-Purple-0, var(--Base-Purple-0, #fbf8ff));
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Purple-4, #7c4dff);
  aspect-ratio: 1;
  height: 32px;
  padding: 0 11px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-15 {
    white-space: initial;
  }
}
.span-16 {
  color: var(--Brand-Purple-0, var(--Base-Purple-0, #fbf8ff));
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--gray-1-white, #fff);
  background-color: var(--Base-Amber-5, #ffab00);
  aspect-ratio: 1;
  height: 32px;
  padding: 0 11px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-16 {
    white-space: initial;
  }
}
.span-17 {
  color: var(--Blue-Gray-10, #3c3e49);
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--gray-1-white, #fff);
  background-color: var(--Base-Blue-grey-3, #dfe0e6);
  aspect-ratio: 1;
  height: 32px;
  padding: 0 7px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-17 {
    white-space: initial;
  }
}
.span-18 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-14 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-40 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-19 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-41 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-41 {
    white-space: initial;
  }
}
.div-42 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-42 {
    white-space: initial;
  }
}
.span-20 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 7px;
  padding: 24px 16px;
}
.div-43 {
  color: var(--Base-Red-4, #ff5546);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-43 {
    white-space: initial;
  }
}
.div-44 {
  color: var(--Base-Red-3, #ff8a80);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-44 {
    white-space: initial;
  }
}
.span-21 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-21 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-45 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.span-22 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-22 {
    padding-right: 20px;
  }
}
.img-15 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-46 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-46 {
    white-space: initial;
  }
}
.div-47 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Red-4, #ff5546);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-16 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-48 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-49 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.img-17 {
  aspect-ratio: 2.5;
  object-fit: contain;
  object-position: center;
  width: 80px;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.span-23 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-18 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-50 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-24 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 20px;
  padding: 24px 16px;
}
.div-51 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  font: 400 12px/133% Rubik, sans-serif;
}
.div-52 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  font: 400 12px/133% Rubik, sans-serif;
}
.span-25 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-53 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-53 {
    white-space: initial;
  }
}
.div-54 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-54 {
    white-space: initial;
  }
}
.span-26 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 80px 0 16px;
}
@media (max-width: 991px) {
  .span-26 {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
}
.div-55 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-55 {
    white-space: initial;
  }
}
.span-27 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-27 {
    padding-right: 20px;
  }
}
.img-19 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-56 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-56 {
    white-space: initial;
  }
}
.div-57 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Green-5, #00c853);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-20 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-58 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-59 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.span-28 {
  color: var(--Brand-Purple-0, var(--Base-Purple-0, #fbf8ff));
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Purple-4, #7c4dff);
  align-self: center;
  aspect-ratio: 1;
  height: 32px;
  margin: auto 0;
  padding: 0 11px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-28 {
    white-space: initial;
  }
}
.span-29 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-21 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-60 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-30 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-61 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-61 {
    white-space: initial;
  }
}
.div-62 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-62 {
    white-space: initial;
  }
}
.img-22 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.span-31 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-31 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-63 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.span-32 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-32 {
    padding-right: 20px;
  }
}
.img-23 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-64 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-64 {
    white-space: initial;
  }
}
.div-65 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Red-4, #ff5546);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-24 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-66 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.div-67 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.div-68 {
  align-items: center;
  border-radius: 100px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--0, #fff);
  align-self: center;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: auto 0;
}
.img-25 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.img-26 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.span-33 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-69 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-69 {
    white-space: initial;
  }
}
.div-70 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-70 {
    white-space: initial;
  }
}
.span-34 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 20px;
  padding: 24px 16px;
}
.div-71 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  font: 400 12px/133% Rubik, sans-serif;
}
.div-72 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  font: 400 12px/133% Rubik, sans-serif;
}
.span-35 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-35 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-73 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-73 {
    white-space: initial;
  }
}
.span-36 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-36 {
    padding-right: 20px;
  }
}
.img-27 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-74 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-74 {
    white-space: initial;
  }
}
.div-75 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Green-5, #00c853);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-28 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-76 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-77 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.div-78 {
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Purple-4, #7c4dff);
  align-self: center;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  width: 32px;
  height: 32px;
  margin: auto 0;
  padding: 0 10px;
}
.img-29 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.span-37 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-30 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-79 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-38 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-80 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-80 {
    white-space: initial;
  }
}
.div-81 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-81 {
    white-space: initial;
  }
}
.span-39 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 7px;
  padding: 24px 16px;
}
.div-82 {
  color: var(--Base-Red-4, #ff5546);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-82 {
    white-space: initial;
  }
}
.div-83 {
  color: var(--Base-Red-3, #ff8a80);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-83 {
    white-space: initial;
  }
}
.span-40 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-40 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-84 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-84 {
    white-space: initial;
  }
}
.span-41 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-41 {
    padding-right: 20px;
  }
}
.img-31 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-85 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-85 {
    white-space: initial;
  }
}
.div-86 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Green-5, #00c853);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-32 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-87 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-88 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.span-42 {
  color: var(--Brand-Purple-0, var(--Base-Purple-0, #fbf8ff));
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Purple-4, #7c4dff);
  align-self: center;
  aspect-ratio: 1;
  height: 32px;
  margin: auto 0;
  padding: 0 11px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-42 {
    white-space: initial;
  }
}
.span-43 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-33 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-89 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-44 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-90 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-90 {
    white-space: initial;
  }
}
.div-91 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-91 {
    white-space: initial;
  }
}
.span-45 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 7px;
  padding: 24px 16px;
}
.div-92 {
  color: var(--Base-Red-4, #ff5546);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-92 {
    white-space: initial;
  }
}
.div-93 {
  color: var(--Base-Red-3, #ff8a80);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-93 {
    white-space: initial;
  }
}
.span-46 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-46 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-94 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.span-47 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-47 {
    padding-right: 20px;
  }
}
.img-34 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-95 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-95 {
    white-space: initial;
  }
}
.div-96 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Amber-5, #ffab00);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-35 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-97 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-98 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.span-48 {
  color: var(--Brand-Purple-0, var(--Base-Purple-0, #fbf8ff));
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Blue-4, #3d5afe);
  align-self: center;
  aspect-ratio: 1;
  height: 32px;
  margin: auto 0;
  padding: 0 11px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-48 {
    white-space: initial;
  }
}
.span-49 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-36 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-99 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-50 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-100 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-100 {
    white-space: initial;
  }
}
.div-101 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-101 {
    white-space: initial;
  }
}
.span-51 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 7px;
  padding: 24px 16px;
}
.div-102 {
  color: var(--Base-Red-4, #ff5546);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-102 {
    white-space: initial;
  }
}
.div-103 {
  color: var(--Base-Red-3, #ff8a80);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-103 {
    white-space: initial;
  }
}
.span-52 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-52 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-104 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.span-53 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-53 {
    padding-right: 20px;
  }
}
.img-37 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-105 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-105 {
    white-space: initial;
  }
}
.div-106 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Amber-5, #ffab00);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-38 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-107 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-108 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.span-54 {
  color: var(--Brand-Purple-0, var(--Base-Purple-0, #fbf8ff));
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Amber-4, #ffc400);
  align-self: center;
  aspect-ratio: 1;
  height: 32px;
  margin: auto 0;
  padding: 0 12px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-54 {
    white-space: initial;
  }
}
.span-55 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-39 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-109 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-56 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-110 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-110 {
    white-space: initial;
  }
}
.div-111 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-111 {
    white-space: initial;
  }
}
.span-57 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 7px;
  padding: 24px 16px;
}
.div-112 {
  color: var(--Base-Red-4, #ff5546);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-112 {
    white-space: initial;
  }
}
.div-113 {
  color: var(--Base-Red-3, #ff8a80);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-113 {
    white-space: initial;
  }
}
.span-58 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-58 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-114 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.span-59 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-59 {
    padding-right: 20px;
  }
}
.img-40 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-115 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-115 {
    white-space: initial;
  }
}
.div-116 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Amber-5, #ffab00);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-41 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-117 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-118 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.span-60 {
  color: var(--Brand-Purple-0, var(--Base-Purple-0, #fbf8ff));
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Green-4, #00e676);
  align-self: center;
  aspect-ratio: 1;
  height: 32px;
  margin: auto 0;
  padding: 0 12px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-60 {
    white-space: initial;
  }
}
.span-61 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-42 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-119 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-62 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-120 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-120 {
    white-space: initial;
  }
}
.div-121 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-121 {
    white-space: initial;
  }
}
.span-63 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 7px;
  padding: 24px 16px;
}
.div-122 {
  color: var(--Base-Red-4, #ff5546);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-122 {
    white-space: initial;
  }
}
.div-123 {
  color: var(--Base-Red-3, #ff8a80);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-123 {
    white-space: initial;
  }
}
.span-64 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-64 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-124 {
  align-self: center;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.span-65 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px;
}
@media (max-width: 991px) {
  .span-65 {
    padding-right: 20px;
  }
}
.img-43 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-125 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-125 {
    white-space: initial;
  }
}
.div-126 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Amber-5, #ffab00);
  align-self: center;
  display: flex;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding: 4px 8px;
}
.img-44 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-127 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin: auto 0;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-128 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: center;
  margin: auto 0;
  font: 400 12px/133% Rubik, sans-serif;
}
.span-66 {
  color: var(--Brand-Purple-0, var(--Base-Purple-0, #fbf8ff));
  letter-spacing: 0.14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21.333px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Purple-4, #7c4dff);
  align-self: center;
  aspect-ratio: 1;
  height: 32px;
  margin: auto 0;
  padding: 0 11px;
  font: 500 14px/143% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .span-66 {
    white-space: initial;
  }
}
.span-67 {
  align-self: center;
  display: flex;
  gap: 8px;
  margin: auto 0;
}
.img-45 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-129 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-68 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px;
}
.div-130 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-130 {
    white-space: initial;
  }
}
.div-131 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-131 {
    white-space: initial;
  }
}
.span-69 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 7px;
  padding: 24px 16px;
}
.div-132 {
  color: var(--Base-Red-4, #ff5546);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-132 {
    white-space: initial;
  }
}
.div-133 {
  color: var(--Base-Red-3, #ff8a80);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-133 {
    white-space: initial;
  }
}
.span-70 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px 0 16px;
}
@media (max-width: 991px) {
  .span-70 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-134 {
  align-self: start;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 0.12px;
  margin-top: 16px;
  font: 400 12px/16px Rubik, sans-serif;
}
.span-71 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 22px 16px 2px;
}
@media (max-width: 991px) {
  .span-71 {
    padding-right: 20px;
  }
}
.img-46 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-135 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  align-self: start;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-135 {
    white-space: initial;
  }
}
.div-136 {
  align-items: center;
  border-radius: 10px;
  background-color: var(--Base-Amber-5, #ffab00);
  align-self: end;
  display: flex;
  margin-top: 22px;
  aspect-ratio: 1.4;
  flex-direction: column;
  justify-content: center;
  padding: 4px 8px;
}
.img-47 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.div-137 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  margin-top: 16px;
  font: 400 12px/16px Rubik, sans-serif;
}
.div-138 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Base-Purple-5, #651fff);
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  align-self: end;
  margin-top: 24px;
  font: 400 12px/133% Rubik, sans-serif;
}
.div-139 {
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid var(--Basic-White, #fff);
  background-color: var(--Base-Purple-4, #7c4dff);
  align-self: start;
  display: flex;
  margin-top: 16px;
  aspect-ratio: 1.1428571428571428;
  flex-direction: column;
  padding: 8px 10px;
}
.img-48 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.span-72 {
  align-self: end;
  display: flex;
  margin-top: 24px;
  gap: 8px;
}
.img-49 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.div-140 {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 500 10px/160% Rubik, sans-serif;
}
.span-73 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 24px 16px 4px;
}
.div-141 {
  color: var(--Base-Blue-grey-10, #3c3e49);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-141 {
    white-space: initial;
  }
}
.div-142 {
  color: var(--Base-Blue-grey-9, #676b7e);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-142 {
    white-space: initial;
  }
}
.span-74 {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  gap: 7px;
  padding: 24px 16px 4px;
}
.div-143 {
  color: var(--Base-Red-4, #ff5546);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-143 {
    white-space: initial;
  }
}
.div-144 {
  color: var(--Base-Red-3, #ff8a80);
  letter-spacing: 0.12px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 12px/133% Rubik, sans-serif;
}
@media (max-width: 991px) {
  .div-144 {
    white-space: initial;
  }
}

.default_logged_in_user_photo {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8f5ff7;
  color: white;
}

.parent {
  border: 1px solid purple;
  position: relative;
}

.child,
.child_wrapper {
  position: absolute;
  top: 0;
  left: 200%;
  border: 1px solid black;
}

.child_wrapper {
  height: 30px;
}

.filters_dropdown {
  margin-top: 0.35em;
  padding: 0.25em;
  border-radius: 0.5em;
  position: absolute;
  z-index: 1;
  background-color: white;
  display: none;
  box-shadow: 2px 4px 16px 3px rgba(0, 0, 0, 0.15);
  font-family: Rubik, sans-serif;
}

.filter_dropdown_option {
  position: relative;
  border-radius: "20px";
  font-size: 0.8rem;
}

.assignee_sub_filter {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter_dropdown_option > div {
  padding: 0.75rem 0.25rem;
  align-items: center;
  gap: 0.5rem;

  font-style: normal;

  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.0075rem;
  color: var(--Base-Blue-grey-10, #3c3e49);
}

.filter_dropdown_option > div.parent_filter_option:hover {
  background: var(--Base-Purple-1, #f3ebff);
  color: var(--Base-Blue-grey-10, #3c3e49);
  border-radius: 0.5em;
}

.filter_dropdown_child_options {
  position: absolute;
  left: 105%;
  top: 0;
  border-radius: 0.5em;
  box-shadow: 2px 4px 16px 3px rgba(0, 0, 0, 0.15);
  font-size: 0.8rem;
  background-color: white;
  width: 120%;
}

.sub_filter_max_height {
  max-height: 66vh;
  overflow: auto;
}

.filter_dropdown_child_options::-webkit-scrollbar {
  display: none;
}

.sub_filter_checkbox {
  /* justify-content: space-between; */
  /* margin-bottom: 0.5em; */

  font-size: 0.8rem;
  font-style: normal;

  letter-spacing: 0.00875rem;
  /* height:40px; */
  /* padding-left:0.8rem; */
}

.two {
  left: 400%;
  border: 1px solid purple;
}

.show {
  display: block;
}

.selected_filter_div {
  /* border:1px solid black;
  padding:5px; */
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  max-width: 160px;
  overflow: hidden;
  border-radius: 0.25rem;
  border: 0.8px solid var(--Base-Blue-grey-7, #9b9fb1);
}

.selected_filter_text {
  color: var(--Base-Purple-5, #651fff);

  /* Body/SM/Medium */

  font-size: 0.875rem;
  font-style: normal;

  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00875rem;
}

.selected_filter_cross_btn {
  display: flex;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0.05206rem 0.05206rem 0.05213rem 0.05206rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--Base-Purple-5, #651fff);

  font-size: 0.875rem;
  font-style: normal;

  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00875rem;
}

.display_selected_filters {
  display: flex;
  /* justify-content:space-around; */
  /* border:1px solid red; */
  /* padding:5px; */
  /* width:50%; */
  /* flex-wrap:wrap; */
  flex-grow: 1;
  align-items: baseline;
  /* justify-content: space-between; */
  /* border:1px solid red; */
}

.display_selected_filters > div {
  white-space: nowrap;
  margin-right: 0.8rem;
  max-width: 160px;
  /*overflow:hidden;
  text-overflow: ellipsis;
 */
}

.clear_all {
  text-decoration: underline;
  /* border:1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border:1px solid blue; */
  /* margin-left:-1.8rem; */
  color: rgba(103, 107, 126, 1);
}

.parent_filter_option {
  display: flex;
  width: 200px;
  /* padding:5px; */
  /* justify-content: space-around; */
  background-color: white;
  /* border:1px solid red; */
}

.parent_filter_option:hover {
  cursor: pointer;
  /* background-color:purple; */
  color: white;
}

.filter_dropdown_child_options > div:hover {
  cursor: pointer;
  background-color: var(--Base-Purple-1, #f3ebff);
  color: black;

  border-radius: 0.5em;
}

.second_level_date_menu {
  /* border:1px solid red; */
  background: powderblue;
}

.date_main_container {
  position: relative;
  height: 2rem;
  padding-top: 0.5rem;
}

.fromTo {
  /* padding: .25em; */
  border-radius: 0.5em;
  position: absolute;
  padding: 0.8em;
  left: 107%;
  top: -125%;

  /* border:1px solid green; */
  /* background-color:powderblue; */
  background-color: white;
  /* padding:0.4rem; */
  box-shadow: 2px 4px 16px 3px rgba(0, 0, 0, 0.15);
}

.fromTo > div:hover {
  cursor: pointer;
  background-color: var(--Base-Purple-1, #f3ebff);
  color: black;
}

.calendar_icon {
  display: flex;
  align-items: center;
}

.date_div_collection {
  display: inline-flex;
  /* margin-right:1.8rem; */
  /* margin-left:-1.8rem; */
  /* border:1px solid red; */
}

.date_div_collection > div {
  margin-right: 10px;
}

.date_last_level {
  /* border-radius: .5em;
display:flex; */
  /* border:1px solid red; */
  /* width:400px; */
  /* width:98%; */
  /* border:1px solid green; */
  border-radius: 0.5em;
  width: 100%;
  position: relative;
}

.date_picker_type {
  margin-left: 10px;
}

.date_picker_value {
  margin-left: auto;
  margin-right: 10px;
}

input[placeholder="Search..."] {
  width: 100%;
}

.search_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25em;
}

.search_box input {
  border: 0;
}

.search_box:hover {
  background-color: transparent;
}

.search_box input:focus {
  border: 0;
}

.filter_dropdown_child_options > div.search_box:hover {
  background-color: white;
}

.closed_tickets {
  margin-right: 0.8rem;
}

.date-sub-menu-container {
  display: flex;
  justify-content: space-between;
}

.datePickerBtnContainer {
  /* border:1px solid red; */
  display: flex;
  /* width:18em; */
  justify-content: space-between;
  /* border:1px solid red; */
  /* width:100%; */
  width: 18em;
  /* border:1px solid red; */
}

.custom-date-picker {
  background-color: white;
  width: 16em;
  height: 10em;
}

.react-datepicker__header {
  text-align: center;
  background-color: white !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--Interactive-Bg-P3, #7c4dff) !important;
  color: white !important;
}

.react-datepicker__day {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
}

.react-datepicker__day--selected {
  background-color: var(--Interactive-Bg-P3, #7c4dff) !important;
}

.react-datepicker {
  margin-top: 50px !important;
  border: 0 !important;
  box-shadow: 2px 4px 16px 3px rgba(0, 0, 0, 0.15) !important;
  /* position:absolute !important;
left:100% !important; */
}
.closed_date_last_level {
  position: relative;
}

.elem_highlighted {
  background-color: var(--Base-Purple-1, #f3ebff);
  /* border-radius:0.5em; */
}
