.EditHeading {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  font-size: 20px !important;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  color: #3c3e49;
  margin-left: 0.1em;
  padding-top: 1.5em;
}
.sectionHeading {
  color: #3c3e49;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.goBackButton {
  display: flex;
}

.mainGrid {
  padding: 0rem 2rem 4rem 4.1rem !important;
  margin-top: 3rem;
  overflow-y: auto;
  max-height: 84vh;
  min-width: 75vw !important;
  font-family: Rubik, sans-serif !important;
}

.footerButtons {
  background-color: transparent;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  border-top: 1px solid var(--Base-Blue-grey-3, #dfe0e6);
  font-size: 14px;
  /* margin-left: 1em; */
  padding-bottom: 1.5em;
}

.saveCancelButton {
  display: flex;
  width: 75vw;
  padding: 1em 1em 0px 2em;
  justify-content: left;
  gap: 4px;
}

.css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item {
  font-size: 12px !important;
}

.css-gi59xn-MuiMultiSectionDigitalClock-root {
  max-height: 30vh !important;
}
.css-16b5y55-MuiPickersLayout-contentWrapper {
  max-height: 10rem;
}

.css-1st8yxe-MuiPickersLayout-root .MuiPickersLayout-actionBar {
  justify-content: center !important;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 1.3rem !important;
}

.loaderDiv {
  align-content: center;
  text-align: end;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subTeamTableGrid {
  padding: 1rem 0em 2em 0em;
  max-width: 68vw;
}

.base-NumberInput-input {
  height: 36px;
  width: 11.5vw;
  font-size: 14px;
  color: #3c3e49;
  border: 1px solid rgb(203, 203, 203);
  border-radius: 4px;
  padding: 16.5px 14px;
  pointer-events: none;
}
.base-NumberInput-input:hover {
  height: 36px;
  width: 11.5vw;
  font-size: 14px;
  color: #3c3e49;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 4px;
  padding: 16.5px 14px;
  pointer-events: none;
}
.base-NumberInput-decrementButton,
.base-NumberInput-incrementButton {
  display: none;
}

.disabledAutoclose {
  cursor: not-allowed;
  opacity: 0.5;
}
.subtextAutoescalation {
  font-size: 0.65rem;
  color: rgb(0, 0, 0, 0.5);
}

.MuiAutocomplete-hasPopupIcon.css-171oqwa-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  align-content: center;
}
.subtextSubteamCustomfield {
  font-size: 0.55rem !important;
  color: rgb(0, 0, 0, 0.5) !important;
}
.subTeamSection {
  opacity: 0.5;
}
