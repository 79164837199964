.tablecellstyleHeader {
  font-size: 11px !important;
  color: #51525b;
  border: 0.1px solid rgb(0, 0, 0, 0.1);
  z-index: 100;
  background-color: red;
}
.tablecellstyle {
  font-size: 12px !important;
  color: #51525b;
  border: 0.1px solid rgb(0, 0, 0, 0.1);
  z-index: 99;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 13px !important;
}

.addSubTeamButton {
  background-color: #f3ebff !important;
  color: #262626 !important;
  font-size: 0.7em !important;
  border-radius: 8px !important;
  width: 8rem;
  height: 2.25rem;
  margin-bottom: 1rem !important;
}

.mandatoryAsterik {
  color: red;
  font-size: 1.2em;
}

.addSubTeamButtonDisabled {
  background-color: #f3ebff !important;
  color: #262626 !important;
  font-size: 0.7em !important;
  border-radius: 8px !important;
  width: 8rem;
  height: 2.25rem;
  margin-bottom: 1rem !important;
  opacity: 0.5;
}
.disabledTabled {
  opacity: 0.5;
}

.tablecellstyleClickup {
  font-size: 12px !important;

  color: #3c3e49;
  border: 0.1px solid rgb(0, 0, 0, 0.1);
  background-color: white;
  z-index: 99;
}

.tableContainer {
  border-radius: 5px;
  border: 0.5px solid rgb(0, 0, 0, 0.1);
}

.tablecellstyleName {
  font-size: 12px !important;
  color: #51525b;
  border: 0.1px solid rgb(0, 0, 0, 0.1);
  z-index: 99;
}
