.topHeadingUserShift {
  margin-top: 1.8rem;
  margin-left: 1.2rem;
}

.weekdaysContainer {
  display: flex;
  margin-top: 1rem;
  border: 2px solid #c7c8d0bd;
  border-radius: 8px;
}

.weekDayContainer {
  border-right: 2px solid #c7c8d0bd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.weekDayContainer:nth-last-child(1) {
  border-right: 0px;
}

.weekDayContainer > div {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
}

.weekDayCheckboxContainer > input {
  width: 1.5rem;
  height: 1rem;
}

.workinghoursContainer {
  margin-right: 2rem;
}
