.mainTableBox {
  width: 88vw;
  margin-left: 1rem;
  margin-top: 3rem;
}

.teamsHeading {
  color: var(--Base-Blue-grey-10, #3c3e49);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 6.5rem;
}

.MuiBadge-root .css-1c32n2y-MuiBadge-root {
  display: none !important;
}

.Mui-TableHeadCell-Content-Actions .MuiBox-root .css-epvm6 {
  display: none !important;
}

.avatar-1 {
  width: 3em;
  height: 3em;
  border-radius: 8px;
  border: 0.5px solid #f3ebff;
  font-style: normal;
}
.avatar-2 {
  width: 3em;
  height: 3em;
  border-radius: 8px;
  border: 0.5px solid #f3ebff;
  color: white;
  margin-left: -1.3em;
  font-style: normal;
}
.avatar-3 {
  width: 3em;
  height: 3em;
  border-radius: 8px;
  border: 0.5px solid #f3ebff;
  color: white;
  margin-left: -1.3em;
  font-style: normal;
}

.avatar-1-alt {
  width: 2.2em;
  height: 2.2em;
  font-size: 1.4em !important;
  border-radius: 8px;
  text-align: center;
  padding-top: 5px;
  border: 0.5px solid #f3ebff;
  font-style: normal;
}
.avatar-2-alt {
  width: 2.2em;
  height: 2.2em;
  font-size: 1.4em !important;
  border-radius: 8px;
  margin-left: -1em;
  text-align: center;
  padding-top: 5px;
  border: 0.5px solid #f3ebff;
  font-style: normal;
}
.avatar-3-alt {
  width: 2.2em;
  height: 2.2em;
  font-size: 1.4em !important;
  border-radius: 8px;
  margin-left: -1em;
  text-align: center;
  padding-top: 5px;
  border: 0.5px solid #f3ebff;
  font-style: normal;
}

tbody > tr:hover > td {
  background-color: unset;
  color: unset !important;
}

.loadingDiv {
  display: flex;
  justify-content: center;
}

.menuButtonMenu {
  border-radius: 8px !important;
  border: 1px solid rgba(179, 136, 255, 0.3) !important;
  background: white;
}

.calendarButtonMenu_grey{
  border-radius: 8px !important;
  border: 1px solid rgba(0,0,0, 0.2) !important;
  background: rgba(199, 197, 197, 0.1) !important;
}

.calendarButtonMenu_green{
  border-radius: 8px !important;
  border: 1px solid rgb(105, 240, 174) !important;
  background: rgba(105, 240, 174, 0.2) !important;
}

.calendarButtonMenu_purple{
  border-radius: 8px !important;
  border: 1px solid rgba(179, 136, 255, 0.3) !important;
  background: rgba(179, 136, 255, 0.2) !important;
}