.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.modal-description {
  padding: 1rem;
}

.sub-team {
  padding: 1rem;
}

.text-editor {
  padding: 1rem;
}

.text-editor .ql-toolbar.ql-snow {
  width: 100%;
  position: absolute;
  z-index: 10;
  border: none;
  border-bottom: 1px solid #d3d4d6;
}

.text-editor .ql-container.ql-snow {
  border: none;
  padding: 2.8rem 1rem 0 1rem;
  min-height: 6rem;
  max-height: 15rem;
  overflow-y: auto;
  scrollbar-color: #d3d4d6 white;
  scrollbar-width: none;
}

.ql-editor.ql-blank{
  height: 15vh !important;
}