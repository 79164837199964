.ticket_fields {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  margin-bottom: 1.5rem;
}

.ticket_fields .ql-snow .ql-tooltip {
  left: 0 !important;
}

.ticket_fields .ql-container.ql-snow {
  background-color: white;
  border: none;
  height: 6rem;
  max-height: 15rem;
  padding: 0 1rem;
  overflow-y: auto;
  scrollbar-color: #d3d4d6 white;
  scrollbar-width: none;
}

.ticket_fields .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #d3d4d6;
}

.ticket_fields_expand {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabelCell-content {
  max-height: 6rem;
  overflow-y: auto;
  scrollbar-color: #d3d4d6 white;
  scrollbar-width: none;
}

.not-editing .ql-container.ql-snow {
  height: auto;
  max-height: 6rem;
  padding: 1rem;
}
