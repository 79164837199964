.btn-icon {
  display: flex;
  align-items: center;
  margin-right: 1.1em;
  color: rgba(0, 0, 0, 0.54);
}

.text {
  color: #3c3e49;
  font-size: 14px;
  line-height: 1.25rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  margin-top: 1.5rem;
}
