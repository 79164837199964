.open_modal_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.25rem;
  align-self: center;
  border-radius: 0.25rem;
  border: 1px solid #cdcfd8;
  color: #3c3e49;
  padding: 0px;
  background-color: white;
  font: 0.9rem "Rubik", sans-serif !important;
}

.btnsContainerForBox button {
  margin-right: 1rem;
  color: black !important;
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-a78wou.Mui-selected {
  background-color: rgb(101, 31, 255) !important;
  width: 1.9rem !important;
  height: 1.9rem !important;
}

.css-o9cot7.Mui-selected,
.css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: rgb(101, 31, 255) !important;
  width: 1.9rem !important;
  height: 1.9rem !important;
}

.MuiDayCalendar-weekContainer {
  margin: 0 !important;
  height: 2rem !important;
}

.MuiButtonBase-root-MuiPickersDay-root {
  width: 1.9rem !important;
  height: 1.9rem !important;
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  width: 1.9rem !important;
  height: 1.9rem !important;
}
