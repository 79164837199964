.EdituserHeading {
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    font-size: 20px;
    margin-left: 0.1em;
    padding-top: 1.5em;
  }
  
  .goBackButton {
    display: flex;
  }
  
  .mainGrid {
    padding: 0em 2em 4em 4.1em;
    margin-top: 3em ;
    gap: 20px;
    overflow-y: auto;
    max-height: 84vh;
    min-width: 75vw;
  }
  
  .footerButtons {
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    border-top: 1px solid var(--Base-Blue-grey-3, #dfe0e6);
    font-size: 20px;
    margin-left: 1em;
    padding-bottom: 1.5em;
  }
  
  .saveCancelButton {
    display: flex;
    width: 75vw;
    padding: 1em 1em 0px 2em;
    justify-content: left;
    gap: 4px;
  }

  .loaderDiv{
    align-content: center;
    text-align: end;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  