.comment_card {
  /* border: 1px solid rgba(223, 224, 230, 1); */
  border-radius: 12px !important;
  box-shadow: none !important;
  margin-bottom: 1.5rem;
  height: auto;
}

.comment_box{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.comment_button {
  background-color: rgba(241, 245, 249, 1) !important;
  padding: 0.5rem !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: black !important;
  
}

.comment_button:hover {
  border: 1px solid rgba(179, 136, 255, 1) !important;
}

