.fixed-bottom {
  position: sticky;
  bottom: 0;
  padding-bottom: 0.9rem;
  background-color: "rgba(248, 249, 250, 1)";
  pointer-events: none;
}
.activity_box {
  border: 1px solid rgba(236, 237, 240, 1);
  border-radius: 4px;
  padding: 0rem 1rem 1rem 1rem;
  height: 90vh;
  margin-top: 2rem;
  overflow-y: auto;
  scrollbar-width: none;
}
